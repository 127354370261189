import { Text } from 'react-native'
import styled from 'styled-components'
import { cursorPointer } from '../../../style/webStyles.js'

export const TextLinkStyle = styled(Text)`
  /* Underlining links is fine, even for Arabic */
  text-decoration: ${(props) => (props.underline ? 'underline' : 'none')};
  text-decoration-color: ${(props) => props.theme.colors[props.color || 'text']};
  color: ${(props) => props.theme.colors[props.color || 'text']};
  ${cursorPointer};
`
