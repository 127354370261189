"use strict";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

import { useCallback } from 'react';
import { useQuery } from '@apollo/client';
import { useFlixApiContext } from '../FlixApiContext';
export var SUBSCRIPTION_PLANS = {
  "kind": "Document",
  "definitions": [{
    "kind": "OperationDefinition",
    "operation": "query",
    "name": {
      "kind": "Name",
      "value": "SubscriptionPlansQuery"
    },
    "variableDefinitions": [{
      "kind": "VariableDefinition",
      "variable": {
        "kind": "Variable",
        "name": {
          "kind": "Name",
          "value": "provider"
        }
      },
      "type": {
        "kind": "NonNullType",
        "type": {
          "kind": "NamedType",
          "name": {
            "kind": "Name",
            "value": "String"
          }
        }
      },
      "directives": []
    }],
    "directives": [],
    "selectionSet": {
      "kind": "SelectionSet",
      "selections": [{
        "kind": "Field",
        "name": {
          "kind": "Name",
          "value": "info"
        },
        "arguments": [{
          "kind": "Argument",
          "name": {
            "kind": "Name",
            "value": "provider"
          },
          "value": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "provider"
            }
          }
        }],
        "directives": [{
          "kind": "Directive",
          "name": {
            "kind": "Name",
            "value": "rest"
          },
          "arguments": [{
            "kind": "Argument",
            "name": {
              "kind": "Name",
              "value": "type"
            },
            "value": {
              "kind": "StringValue",
              "value": "SubscriptionPlans",
              "block": false
            }
          }, {
            "kind": "Argument",
            "name": {
              "kind": "Name",
              "value": "path"
            },
            "value": {
              "kind": "StringValue",
              "value": "info/plans/{args.provider}",
              "block": false
            }
          }]
        }],
        "selectionSet": {
          "kind": "SelectionSet",
          "selections": [{
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "stripeApiKey"
            },
            "arguments": [],
            "directives": []
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "paypalClientId"
            },
            "arguments": [],
            "directives": []
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "choosePlan"
            },
            "arguments": [],
            "directives": [{
              "kind": "Directive",
              "name": {
                "kind": "Name",
                "value": "type"
              },
              "arguments": [{
                "kind": "Argument",
                "name": {
                  "kind": "Name",
                  "value": "name"
                },
                "value": {
                  "kind": "StringValue",
                  "value": "SubsChoosePlan",
                  "block": false
                }
              }]
            }],
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "title"
                },
                "arguments": [],
                "directives": []
              }]
            }
          }, {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "plans"
            },
            "arguments": [],
            "directives": [{
              "kind": "Directive",
              "name": {
                "kind": "Name",
                "value": "type"
              },
              "arguments": [{
                "kind": "Argument",
                "name": {
                  "kind": "Name",
                  "value": "name"
                },
                "value": {
                  "kind": "StringValue",
                  "value": "SubscriptionPlan",
                  "block": false
                }
              }]
            }],
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [{
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "sku"
                },
                "arguments": [],
                "directives": []
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "type"
                },
                "arguments": [],
                "directives": []
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "title"
                },
                "arguments": [],
                "directives": []
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "subTitle"
                },
                "arguments": [],
                "directives": []
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "description"
                },
                "arguments": [],
                "directives": []
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "price"
                },
                "arguments": [],
                "directives": []
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "currency"
                },
                "arguments": [],
                "directives": []
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "currencySymbol"
                },
                "arguments": [],
                "directives": []
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "hasFreeTrial"
                },
                "arguments": [],
                "directives": []
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "paymentNote"
                },
                "arguments": [],
                "directives": []
              }, {
                "kind": "Field",
                "name": {
                  "kind": "Name",
                  "value": "promoBlock"
                },
                "arguments": [],
                "directives": [{
                  "kind": "Directive",
                  "name": {
                    "kind": "Name",
                    "value": "type"
                  },
                  "arguments": [{
                    "kind": "Argument",
                    "name": {
                      "kind": "Name",
                      "value": "name"
                    },
                    "value": {
                      "kind": "StringValue",
                      "value": "SubsPlanPromo",
                      "block": false
                    }
                  }]
                }],
                "selectionSet": {
                  "kind": "SelectionSet",
                  "selections": [{
                    "kind": "Field",
                    "name": {
                      "kind": "Name",
                      "value": "v1"
                    },
                    "arguments": [],
                    "directives": [{
                      "kind": "Directive",
                      "name": {
                        "kind": "Name",
                        "value": "type"
                      },
                      "arguments": [{
                        "kind": "Argument",
                        "name": {
                          "kind": "Name",
                          "value": "name"
                        },
                        "value": {
                          "kind": "StringValue",
                          "value": "SubsPlanPromoV1",
                          "block": false
                        }
                      }]
                    }],
                    "selectionSet": {
                      "kind": "SelectionSet",
                      "selections": [{
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "title"
                        },
                        "arguments": [],
                        "directives": []
                      }, {
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "badge"
                        },
                        "arguments": [],
                        "directives": [{
                          "kind": "Directive",
                          "name": {
                            "kind": "Name",
                            "value": "type"
                          },
                          "arguments": [{
                            "kind": "Argument",
                            "name": {
                              "kind": "Name",
                              "value": "name"
                            },
                            "value": {
                              "kind": "StringValue",
                              "value": "SubsPlanBadge",
                              "block": false
                            }
                          }]
                        }],
                        "selectionSet": {
                          "kind": "SelectionSet",
                          "selections": [{
                            "kind": "Field",
                            "name": {
                              "kind": "Name",
                              "value": "text"
                            },
                            "arguments": [],
                            "directives": []
                          }, {
                            "kind": "Field",
                            "name": {
                              "kind": "Name",
                              "value": "type"
                            },
                            "arguments": [],
                            "directives": []
                          }, {
                            "kind": "Field",
                            "name": {
                              "kind": "Name",
                              "value": "color"
                            },
                            "arguments": [],
                            "directives": []
                          }]
                        }
                      }, {
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "benefits"
                        },
                        "arguments": [],
                        "directives": [{
                          "kind": "Directive",
                          "name": {
                            "kind": "Name",
                            "value": "type"
                          },
                          "arguments": [{
                            "kind": "Argument",
                            "name": {
                              "kind": "Name",
                              "value": "name"
                            },
                            "value": {
                              "kind": "StringValue",
                              "value": "SubsPlanBenefits",
                              "block": false
                            }
                          }]
                        }],
                        "selectionSet": {
                          "kind": "SelectionSet",
                          "selections": [{
                            "kind": "Field",
                            "name": {
                              "kind": "Name",
                              "value": "align"
                            },
                            "arguments": [],
                            "directives": []
                          }, {
                            "kind": "Field",
                            "name": {
                              "kind": "Name",
                              "value": "icon"
                            },
                            "arguments": [],
                            "directives": []
                          }, {
                            "kind": "Field",
                            "name": {
                              "kind": "Name",
                              "value": "items"
                            },
                            "arguments": [],
                            "directives": []
                          }]
                        }
                      }, {
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "benefitsMore"
                        },
                        "arguments": [],
                        "directives": [{
                          "kind": "Directive",
                          "name": {
                            "kind": "Name",
                            "value": "type"
                          },
                          "arguments": [{
                            "kind": "Argument",
                            "name": {
                              "kind": "Name",
                              "value": "name"
                            },
                            "value": {
                              "kind": "StringValue",
                              "value": "SubsPlanBenefits",
                              "block": false
                            }
                          }]
                        }],
                        "selectionSet": {
                          "kind": "SelectionSet",
                          "selections": [{
                            "kind": "Field",
                            "name": {
                              "kind": "Name",
                              "value": "align"
                            },
                            "arguments": [],
                            "directives": []
                          }, {
                            "kind": "Field",
                            "name": {
                              "kind": "Name",
                              "value": "icon"
                            },
                            "arguments": [],
                            "directives": []
                          }, {
                            "kind": "Field",
                            "name": {
                              "kind": "Name",
                              "value": "items"
                            },
                            "arguments": [],
                            "directives": []
                          }]
                        }
                      }, {
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "billingNoteEmphasised"
                        },
                        "arguments": [],
                        "directives": []
                      }, {
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "billingNote"
                        },
                        "arguments": [],
                        "directives": []
                      }, {
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "buttonText"
                        },
                        "arguments": [],
                        "directives": []
                      }, {
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "closingPromo"
                        },
                        "arguments": [],
                        "directives": []
                      }]
                    }
                  }]
                }
              }]
            }
          }]
        }
      }]
    }
  }],
  "loc": {
    "start": 0,
    "end": 1124,
    "source": {
      "body": "\n  query SubscriptionPlansQuery($provider: String!) {\n    info(provider: $provider) @rest(type: \"SubscriptionPlans\", path: \"info/plans/{args.provider}\") {\n      stripeApiKey\n      paypalClientId\n      choosePlan @type(name: \"SubsChoosePlan\") {\n        title\n      }\n      plans @type(name: \"SubscriptionPlan\") {\n        sku\n        type\n        title\n        subTitle\n        description\n        price\n        currency\n        currencySymbol\n        hasFreeTrial\n        paymentNote\n        promoBlock @type(name: \"SubsPlanPromo\") {\n          v1 @type(name: \"SubsPlanPromoV1\") {\n            title\n            badge @type(name: \"SubsPlanBadge\") {\n              text\n              type\n              color\n            }\n            benefits @type(name: \"SubsPlanBenefits\") {\n              align\n              icon\n              items\n            }\n            benefitsMore @type(name: \"SubsPlanBenefits\") {\n              align\n              icon\n              items\n            }\n            billingNoteEmphasised\n            billingNote\n            buttonText\n            closingPromo\n          }\n        }\n      }\n    }\n  }\n",
      "name": "GraphQL request",
      "locationOffset": {
        "line": 1,
        "column": 1
      }
    }
  }
};
var emptyArray = [];
var emptyObject = {};

var formatResult = function formatResult(result) {
  var _data$info$choosePlan, _data$info$plans;

  var data = result.data;
  return _objectSpread(_objectSpread({}, result), {}, {
    data: {
      choosePlan: (_data$info$choosePlan = data === null || data === void 0 ? void 0 : data.info.choosePlan) !== null && _data$info$choosePlan !== void 0 ? _data$info$choosePlan : emptyObject,
      plans: (_data$info$plans = data === null || data === void 0 ? void 0 : data.info.plans) !== null && _data$info$plans !== void 0 ? _data$info$plans : emptyArray,
      stripeApiKey: data === null || data === void 0 ? void 0 : data.info.stripeApiKey,
      paypalClientId: data === null || data === void 0 ? void 0 : data.info.paypalClientId
    }
  });
};

export var useSubscriptionPlansQuery = function useSubscriptionPlansQuery() {
  var _ref = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
      provider = _ref.provider;

  var options = arguments.length > 1 ? arguments[1] : undefined;

  var _useFlixApiContext = useFlixApiContext(),
      paymentProvider = _useFlixApiContext.paymentProvider;

  var result = useQuery(SUBSCRIPTION_PLANS, _objectSpread(_objectSpread({}, options), {}, {
    variables: {
      provider: provider || paymentProvider
    }
  }));
  return formatResult(result);
};
export var useSubscriptionPlansFetch = function useSubscriptionPlansFetch() {
  var _ref2 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
      provider = _ref2.provider;

  var _useFlixApiContext2 = useFlixApiContext(),
      client = _useFlixApiContext2.client,
      paymentProvider = _useFlixApiContext2.paymentProvider;

  var finalProvider = provider || paymentProvider;
  return useCallback(function () {
    return client.query({
      query: SUBSCRIPTION_PLANS,
      variables: {
        provider: finalProvider
      }
    }).then(formatResult);
  }, [client, finalProvider]);
};