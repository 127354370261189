import { Helmet } from 'react-helmet-async'
import { useIntl } from 'react-intl'
import { last } from 'lodash'
import { useAuthStatusQuery, useSubscriptionPlansQuery } from '@mwxltd/flix-api-client'
import { CMSSectionList } from '@flix/common/domain/cms'
import { faqSectionCostUS, sections } from '@flix/common/domain/landing-pages/home4Sections'
import { FillScreen } from '../../../components/layout/FillScreen/FillScreen'
import { NotInRegion } from '../../../containers/errors/NotInRegion/NotInRegion'
import { canonicalUrl } from '../../../utils/htmlHead'
import meta from '../../metaDescriptions'

// Transparent header configured in `useNavBarTransparency.js`
const HomePage = () => {
  const intl = useIntl()

  const { loading, data } = useAuthStatusQuery()

  // Prefetch plans for use when the subscription wizard is initiated, only once loaded
  useSubscriptionPlansQuery({ provider: 'stripe' }, { skip: loading })

  // Returns the default authStatus while loading
  const { supportedRegion = true, region = 'US' } = data?.authStatus ?? {}

  if (!supportedRegion) {
    return <NotInRegion />
  }

  if (!loading && region !== 'GB' && last(sections)?.items?.[2]) {
    last(sections).items[2].description = faqSectionCostUS
  }

  return (
    <>
      <Helmet>
        <title>{intl.formatMessage(meta.homeTitle)}</title>
        <meta name="description" content={intl.formatMessage(meta.home)} />
        <meta property="og:description" content={intl.formatMessage(meta.home)} />
        <meta property="og:url" content={canonicalUrl('/')} />
        <link rel="canonical" href={canonicalUrl('/')} />
      </Helmet>

      <FillScreen>
        <CMSSectionList sections={sections} region={region} />
      </FillScreen>
    </>
  )
}

export default HomePage
