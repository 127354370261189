import { defineMessages } from 'react-intl'

export default defineMessages({
  heading: {
    id: 'app.error.NotInRegion.heading',
    defaultMessage: 'Coming soon to your region',
  },
  notYet: {
    id: 'app.error.NotInRegion.notYet',
    defaultMessage: "We don't have any movies available in your region yet, but why don't you",
  },
  registerInterest: {
    id: 'app.error.NotInRegion.registerInterest',
    defaultMessage: 'Register your interest',
  },
  letYouKnow: {
    id: 'app.error.NotInRegion.letYouKnow',
    defaultMessage: "We'll let you know as soon as you can access our exclusive content.",
  },
  cantAccess: {
    id: 'app.error.NotInRegion.cantAccess',
    defaultMessage:
      "Flix Premiere is currently available in the USA and UK. If you can't access our content from these territories please {tellUs}.",
  },
  letUsKnow: {
    id: 'app.error.NotInRegion.letUsKnow',
    defaultMessage: 'let us know',
  },
})
