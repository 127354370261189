export const sections = [
  {
    type: 'hero',
    heading: 'Discover the Very Best from Independent Film',
    description: 'Award-winning movies, film festival greats and much more…',
    bgImgIdLandscape: 'web/home4/2024-homepage-hero-banner',
    bgImgIdPortrait: 'web/home4/2024-homepage-hero-banner-mob',
    button: { default: { text: 'Get started', action: 'sign-up' } },
  },
  {
    type: 'descr_bg_image_one_col',
    heading: 'New Premieres Every Week',
    description:
      'Bring the red carpet experience to your home with a new film added every weekend.',
    image: {
      publicId: 'web/home4/2024-new-premieres-every-week-v2',
      aspectRatio: 1920 / 1080,
    },
    imageMobile: {
      publicId: 'web/home4/2024-new-premieres-every-week-mob-2-v2',
      aspectRatio: 810 / 1080,
    },
  },
  {
    type: 'descr_bg_image_one_col',
    heading: 'Watch Anytime, Anywhere',
    description:
      'Stream our movies on any device and unlimited screens, wherever you are. Download movies before you go and enjoy independent films on the move.',
    image: {
      publicId: 'web/home4/2024-watch-anytime-v2',
      aspectRatio: 1920 / 1080,
    },
    imageMobile: {
      publicId: 'web/home4/2024-watch-antytime-devices-mob-v2',
      aspectRatio: 810 / 1080,
    },
  },
  {
    type: 'descr_bg_image_one_col',
    heading: 'Host a Screening Party',
    description:
      'Invite friends and family to a Screening Party. Watch, chat and enjoy the best of indie cinema with virtual viewings!',
    image: {
      publicId: 'web/home4/2024-screening-party-v2',
      aspectRatio: 1920 / 1080,
    },
    imageMobile: {
      publicId: 'web/home4/2024-screening-party-mob',
      aspectRatio: 810 / 1080,
    },
  },
  {
    type: 'descr_image_one_col',
    defaultHeight: 260,
    heading: 'Get Our Movies on Your Favorite Devices',
    description: '',
    color: 'text',
    image: { publicId: 'web/home4/2024-homepage-devices-white-v2', aspectRatio: 2366 / 170 },
    imageMobile: {
      publicId: 'web/home4/2024-homepage-devices-white-mob-v2',
      aspectRatio: 1200 / 316,
    },
  },
  {
    type: 'descr_image_one_col',
    defaultHeight: 260,
    bgImgIdLandscape: 'web/home4/2024-get-started',
    bgImgIdPortrait: 'web/home4/2024-get-started-mob',
    heading: '',
    description: 'Ready to Enjoy the Very Best from Independent Cinema?',
    button: { default: { text: 'Get started', action: 'sign-up' } },
    gradient: { y: 0.7, strength: 8, subGradTransforms: { y: -0.7 } },
  },
  {
    type: 'banner_ad',
    zone: 'homepage',
    placementSm: 'belowFoldLeaderboard',
    placementXs: 'belowFoldLeaderboardMobile',
  },

  {
    type: 'expanding_text_list',
    heading: 'Frequently Asked Questions',
    items: [
      {
        heading: 'What is Flix Premiere?',
        description:
          'Flix Premiere is an independent movie streaming platform that showcases top movies from prestigious film festivals all over the world. Many of our films have won awards or been nominated at Berlinale, TIFF, SXSW, Cannes, AFI and more. From drama to thrillers, comedies to documentaries, we aim to highlight the best of independent film so we can give our audience the opportunity to discover fresh movies from important new voices from the USA, UK and across the globe.',
      },
      {
        heading: 'What Makes Flix Premiere Different?',
        description:
          'We are a streaming service where independent movies can be truly seen and experienced. We bring you closer to the untold stories with exciting features, in-depth info, weekly film premieres, and upcoming film festivals. ALL hand-picked films: NO algorithms! Our passion for indie gems and undiscovered filmmakers is reflected in the care we give to each film that arrives on our service.',
      },
      {
        heading: 'How Much Does Flix Premiere Cost?',
        description:
          "You can sign up for a free 30-day trial with no commitment and the ability to cancel anytime. A standard monthly subscription will then cost £5.99 once the free trial is over. Or sign up for a 6-monthly subscription with an upfront cost of £17.99 - that's the equivalent of £2.99 a month!",
      },
      {
        heading: 'How Can I Watch Flix Premiere?',
        description:
          'We are live online and via 19 platforms including Apple TV, Chromecast, Roku, Amazon Fire TV Stick, Xbox, and many popular Smart TVs (Samsung, LG, etc). Once subscribed, you can also download our films for a limited period and take them anywhere with you on your iOS or Android devices. Sign up to a 30-day free trial to try out our service today.',
      },
    ],
  },
]

export const faqSectionCostUS =
  "You can sign up for a free 30-day trial with no commitment and the ability to cancel anytime. A standard monthly subscription will then cost $6.99 once the free trial is over. Or sign up for a 6-monthly subscription with an upfront cost of $20.99 - that's the equivalent of $3.49 a month!"
