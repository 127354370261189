import { FormattedMessage } from 'react-intl'
import { View } from 'react-native'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import { useAuthStatus } from '@mwxltd/flix-api-client'
import { Button } from '@flix/common/elements/controls'
import Image from '@flix/common/elements/images/Image/Image'
import { A, H1, Text } from '@flix/common/elements/typography'
import messages from './messages'

const Container = styled(View)`
  margin: ${(props) => props.theme.spacing.large}px auto;
  padding: 0 ${(props) => props.theme.spacing.medium}px;
`

const ImageContent = styled(Image)`
  margin: ${(props) => props.theme.spacing.large}px auto;
`

const LargeText = styled(Text)`
  font-size: ${(props) => props.theme.fontSizes.large}px;
  line-height: ${(props) => props.theme.fontSizes.large * 1.3}px;
  margin-bottom: ${(props) => props.theme.spacing.large}px;
`

const SmallText = styled(Text)`
  font-style: italic;
  font-size: ${(props) => props.theme.fontSizes.small}px;
  line-height: ${(props) => props.theme.fontSizes.small * 1.3}px;
  margin-bottom: ${(props) => props.theme.spacing.large}px;
`

export const NotInRegion = () => {
  const history = useHistory()
  const authStatus = useAuthStatus()
  return (
    <Container>
      <ImageContent height={100} width={(100 * 192) / 131} publicId="web/flix-logo-white-192" />

      <H1 center>
        <FormattedMessage {...messages.heading} />
      </H1>

      <ImageContent width={330} height={207} publicId="world-map" />

      {!authStatus.hasSignedIn && (
        <>
          <LargeText textAlign="center">
            <FormattedMessage {...messages.notYet} />
          </LargeText>
          <Button
            onPress={() => history.push('/register-interest')}
            intlMsg={messages.registerInterest}
            variant="primary"
            alignSelf="center"
            mb="large"
          />
        </>
      )}

      <LargeText textAlign="center">
        <FormattedMessage {...messages.letYouKnow} />
      </LargeText>

      <SmallText textAlign="center">
        <FormattedMessage
          {...messages.cantAccess}
          values={{
            tellUs: (
              <A href="mailto:support@flixpremiere.com">
                <FormattedMessage {...messages.letUsKnow} />
              </A>
            ),
          }}
        />
      </SmallText>
    </Container>
  )
}

export default NotInRegion
